.App {
}

.AppBar {
    text-align: right;
    color: white;
}

.Home_Welcome {
    padding-top: 2rem;
    text-align: center;
    color: #ffffff;
}

.Home_Contact {
    padding-top: 2rem;
    text-align: center;
}

.Home_Actions {
    padding-top: 2rem;
    text-align: center;
}

.carousel-item {
    width: 100%;
}

.carousel-item img {
    width: 100%;
}

.AboutMeCard {
    margin: 2rem;
    max-width: 50vw;
}
